import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import {
  Autocomplete,
  Button,
  Card,
  Container,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

export default function FormDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const axios = useAxiosPrivate();
  const [name, setName] = useState("");
  const [approvalDetail, setApprovalDetail] = useState([]);
  const [roles, setRoles] = useState([]);

  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");

  const [selectedUser, setSelectedUser] = useState(null);

  const minValue = 1;
  const maxValue = 100;

  const fetchData = async () => {
    try {
      const res = await axios.get(`/master-form-approval/approval/${id}`);
      const data = res.data.data;
      setSelectedUser({ label: data.user.name, value: data.user.id });
      setApprovalDetail(data.approvalDetail.map((item) => ({ ...item, user: { label: item.user.name, value: item.user.id } })));
    } catch (err) {
      err.response?.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  const fetchUser = async () => {
    const res = await axios.get(`/master/user${search ? `?search=${search}` : ""}`);
    setUsers(
      res.data.data.results.map((user) => ({
        label: user.name,
        value: user.id,
      }))
    );
  };

  const fetchRoles = async () => {
    try {
      const res = await axios.get(`/master/role`);
      setRoles(res.data.data.results);
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  useEffect(() => {
    document.title = `${id ? "Edit" : "Create"} Approval - ${process.env.REACT_APP_NAME}`;
    fetchRoles();
    fetchUser();
    if (id) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [id]);

  const handleAdd = () => {
    setApprovalDetail((prev) => [...prev, { edit: true, order: prev.length + 1, role: "" }]);
  };

  const handleEditRow = (index) => {
    setApprovalDetail((prev) => {
      const newData = [...prev];
      newData[index].edit = true;
      return newData;
    });
  };

  const handleDeleteRow = (index) => {
    setApprovalDetail((prev) => {
      const newData = [...prev];
      newData.splice(index, 1);
      return newData;
    });
  };

  const handleSearch = (val) => {
    setSearch((prev) => val);
    fetchUser();
  };

  const handleSelect = (val) => {
    setSelectedUser((prev) => val);
  };

  const handleSubmit = async () => {
    try {
      const data = {
        userId: selectedUser.value,
        approvalDetail: approvalDetail.map((item) => ({
          order: item.order,
          userId: item.user.value,
        })),
      };
      if (!id) {
        const res = await axios.post(`/master-form-approval/approval`, data);
        res.data.message.reverse().forEach((message) => {
          toast.success(message);
        });
      } else {
        const res = await axios.put(`/master-form-approval/approval/${id}`, data);
        res.data.message.reverse().forEach((message) => {
          toast.success(message);
        });
      }

      navigate("/master-form-approval");
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  // if (loading) return <Loading />

  return (
    <>
      <h1>{`${id ? "Edit" : "Create"}`} Approval</h1>
      <Container sx={{ width: "100vw" }}>
        <Card sx={{ padding: 5 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={12}>
              <Autocomplete
                onChange={(event, value) => handleSelect(value)}
                onKeyUp={(event) => handleSearch(event.target.value)}
                id="combo-box-demo"
                value={selectedUser}
                options={users}
                sx={{ width: "100%", paddingTop: 2 }}
                renderInput={(params) => <TextField {...params} required label={t("component.text.user")} />}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: 2 }} justifyContent="flex-end">
            <Grid item xs={12}>
              <Typography variant="h6">Approval Detail</Typography>
            </Grid>
            <Grid item xs={12}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{t("component.text.order")}</TableCell>
                    <TableCell align="center">{t("component.text.user")}</TableCell>
                    <TableCell align="center">{t("component.text.action")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {approvalDetail.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        {t("component.text.noData")}
                      </TableCell>
                    </TableRow>
                  )}
                  {approvalDetail.length > 0 &&
                    approvalDetail.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">
                          {row.edit ? (
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              fullWidth
                              type="number"
                              value={row.order}
                              onChange={(e, value) => {
                                setApprovalDetail((prev) => {
                                  const newData = [...prev];
                                  newData[index].order = Math.min(Math.max(value, minValue), maxValue);
                                  console.log(newData);
                                  return newData;
                                });
                              }}
                            />
                          ) : (
                            row.order
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {row.edit ? (
                            <Autocomplete
                              onChange={(e, value) => {
                                console.log(value);
                                setApprovalDetail((prev) => {
                                  const newData = [...prev];
                                  newData[index].user = users.find((user) => user.value === value.value);
                                  console.log(users);
                                  return newData;
                                });
                              }}
                              onKeyUp={(event) => handleSearch(event.target.value)}
                              id="combo-box-demo"
                              value={row.user}
                              options={users}
                              sx={{ width: "100%", paddingTop: 2 }}
                              renderInput={(params) => <TextField {...params} required label={t("component.text.user")} />}
                            />
                          ) : (
                            row.user.label
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Grid container spacing={1}>
                            <Grid item xs>
                              {row.edit ? (
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="success"
                                  onClick={() => {
                                    setApprovalDetail((prev) => {
                                      const newData = [...prev];
                                      newData[index].edit = false;
                                      return newData;
                                    });
                                  }}
                                >
                                  {t("component.button.save")}
                                </Button>
                              ) : (
                                <Button size="small" variant="contained" color="warning" onClick={() => handleEditRow(index)}>
                                  {t("component.button.edit")}
                                </Button>
                              )}
                            </Grid>
                            <Grid item xs>
                              <Button size="small" variant="contained" color="danger" onClick={() => handleDeleteRow(index)}>
                                {t("component.button.delete")}
                              </Button>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={handleAdd}>
                {t("component.button.addStep")}
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: 2 }} justifyContent="center">
            <Grid item>
              <Button variant="contained" color="success" onClick={handleSubmit}>
                {t("component.button.save")}
              </Button>
            </Grid>
            <Grid item>
              <Link to="/master-form-approval">
                <Button variant="contained" color="warning">
                  {t("component.button.cancel")}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </>
  );
}
